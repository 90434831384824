import { backendConfig } from "../constants/authConfig";

export async function importHcpDetails(accessToken, data, createdby) {
  const headers = new Headers();
  // const bearer = `Bearer ${accessToken}`;
  headers.append("Content-Type", "application/json");
  headers.append("authorizationtoken", accessToken);
  const hcpdetails = JSON.stringify({
    hcpdetails: data,
    created_by: createdby
  });
  const options = {
    method: "POST",
    headers: headers,
    body: hcpdetails,
  };

  return fetch(
    `${backendConfig.backendEndpoint}hcpmapping-importhcpdetails`,
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function getHCPDetails(accessToken) {
  const headers = new Headers();
  // const bearer = `Bearer ${accessToken}`;
  headers.append("Content-Type", "application/json");
  headers.append("authorizationtoken", accessToken);
  const options = {
    method: "POST",
    headers: headers,
  };

  return fetch(
    `${backendConfig.backendEndpoint}hcpmapping-gethcpmapping`,
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export function getFilteredHcp(accessToken, data) {
  const headers = new Headers();
  // const bearer = `Bearer ${accessToken}`;
  headers.append("Content-Type", "application/json");
  headers.append("authorizationtoken", accessToken);
  const hcpData = JSON.stringify({
    hcp_name: data.hcp_name,
    national_id: data.national_id,
    professional_id: data.professional_id,
    customer_id: data.customer_id,
  });
  const options = {
    method: "POST",
    headers: headers,
    body: hcpData,
  };
  return fetch(
    `${backendConfig.backendEndpoint}hcpmapping-filterhcpmapping`,
    options,
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export function getHCPDetailsByEmail(accessToken, data) {
  const headers = new Headers();
  // const bearer = `Bearer ${accessToken}`;
  headers.append("Content-Type", "application/json");
  headers.append("authorizationtoken", accessToken);
  const hcpData = JSON.stringify({
    email_id: data.email_id
  });
  const options = {
    method: "POST",
    headers: headers,
    body: hcpData,
  };
  return fetch(
    `${backendConfig.backendEndpoint}hcpmapping-gethcpmappingbyemailid`,
    options,
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export function getMeetingIdVerification(accessToken, data) {
  const headers = new Headers();
  // const bearer = `Bearer ${accessToken}`;
  headers.append("Content-Type", "application/json");
  headers.append("authorizationtoken", accessToken);
  const hcpData = JSON.stringify({
    meeting_id: decodeURI(data.meeting_id)
  });
  const options = {
    method: "POST",
    headers: headers,
    body: hcpData,
  };
  return fetch(
    `${backendConfig.backendEndpoint}meeting-checkmeetingid`,
    options,
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}