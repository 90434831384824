import React, { useContext, useEffect, useState } from "react";
import { SessionContext } from "../providers/provider";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GenerateQRPage from "./generateQRPage";
import AdminDashBoard from "./adminDashboard";
import MeetingOwnerDashBoard from "./meetingOwnerDashboard";
import HcpData from "./viewHCPData";
import HcpDashboard from "./hcpDashboard";
import HCPForm from "./submitHCPDetails";
import { LoadingScreen } from "../components/common";
import PageNotFound from "./pageNotFound";
import SessionModal from "../components/sessionModal";

function MainPage() {
  const userSession = useContext(SessionContext);
  const [role, setRole] = useState("");
  useEffect(() => {
    if (userSession.userRole !== "") {
      setRole(userSession.userRole);
    }
  }, [userSession]);
  return (
    <>
      <Router>
        <SessionModal />
          <Routes>
            <Route
              exact={true}
              path="/"
              element={
                role === "Admin" ? (
                  <AdminDashBoard />
                ) : role === "Meeting Owner" ? (
                  <MeetingOwnerDashBoard />
                ) : role === "HCP" ? (
                  <HcpDashboard />
                ) : (
                  <LoadingScreen />
                )
              }
            />
            {role === "Admin" && (
              <Route
                exact={true}
                path="/admin/dashboard"
                element={<AdminDashBoard />}
              />
            )}
            {role === "Meeting Owner" && (
              <Route
                exact={true}
                path="/mo/generateqr"
                element={<GenerateQRPage />}
              />
            )}
            {role === "Admin" && (
              <Route
                exact={true}
                path="/admin/viewhcpdata"
                element={<HcpData />}
              />
            )}
            {role === "HCP" && (
              <Route
                exact={true}
                path="/hcp/dashboard"
                element={<HcpDashboard />}
              />
            )}
            {role === "Meeting Owner" && (
              <Route
                exact={true}
                path="/mo/dashboard"
                element={<MeetingOwnerDashBoard />}
              />
            )}
            {role === "HCP" && (
              <Route
                exact={true}
                path="/submit/hcpData"
                element={<HCPForm />}
              />)}
            {role === "HCP" && (
              <Route
                exact={true}
                path="/submit/hcpData/:id"
                element={<HCPForm />}
              />)}
            {(role === "Admin" ||
              role === "Meeting Owner" ||
              role === "HCP") && (
                <Route exact={true}
                  path="*"
                  element={<PageNotFound />} />
              )}
          </Routes>
      </Router>
    </>
  );
}

export default MainPage;
