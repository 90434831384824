import { useEffect, useState } from "react";
import {
  Table,
  Button,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Typography,
} from "@mui/material";
import CardActions from "@mui/material/CardActions";
import {
  StyledTableCell,
  StyledTableCellRow,
  StyledtableHead,
} from "./styledComponents";
import { getSignatureByAttendeeId } from "../services/attendeeservice";

export default function DataTable(props) {
  const [readMore, setReadMore] = useState(false);
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(5);

  function handleSignDownload(attendee_id, fileName) {
    async function downloadData() {
      let accessToken = localStorage.getItem("apitoken");
      let response = await getSignatureByAttendeeId(accessToken, attendee_id);
      const newsign = response[0][0].hcp_signature;
      const link = document.createElement("a");
      link.href = newsign;
      link.setAttribute("download", `${fileName}.png`);
      document.body.appendChild(link);
      link.click();
    }
    downloadData();
  }

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }
  let dataList =
    props && props.rows.length > 0 ? Object.keys(props.rows[0]) : [];

  function handleReadMore() {
    setReadMore(!readMore);
  }

  useEffect(() => {
    setpg(0);
  }, [props.rows]);

  return (
    <>
      {/* desktop dashboard table */}
      <TableContainer
        component={Paper}
        sx={{
          // flexGrow: 1,
          display: { xs: "none", sm: "none", md: "flex" },
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#1976d2",
                textAlign: "center",
                height: "20px",
              }}
            >
              {props.tableHeader.map((header) => (
                <TableCell
                  sx={{
                    color: "white",
                    textAlign: "center",
                    fontWeight: "700",
                    fontSize: "14",
                    height: "30px",
                    padding: "8px 4px",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {props.rows.length === 0 ? (
            <TableBody>
              <TableRow
                sx={{ "&:last-child td,&:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    textAlign: "center",
                  }}
                  colSpan={9}
                >
                  No Results Found
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.rows.slice(pg * rpg, pg * rpg + rpg).map((rows) => (
                <TableRow
                  key={props.rows.name}
                  sx={{ "&:last-child td,&:last-child th": { border: 0 } }}
                >
                  <>
                    {dataList.length > 6 ? (
                      <>
                        <TableCell sx={{ textAlign: "center" }}>
                          {rows.hcp_firstname}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {rows.hcp_lastname}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {rows.hcp_specialization}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {rows.professional_id}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {rows.national_id}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {rows.email_id}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {rows.meeting_id}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {new Date(rows.meeting_date).toLocaleDateString(
                            "en-GB"
                          )}
                        </TableCell>

                        <TableCell sx={{ textAlign: "center" }}>
                          {rows.hcp_signature_filename.length === 0 ? (
                            "NA"
                          ) : (
                            <Button style={{ textTransform: "none" }}>
                              <Typography
                                download={rows.hcp_signature_filename}
                                onClick={() =>
                                  handleSignDownload(
                                    rows.attendee_id,
                                    rows.hcp_signature_filename
                                  )
                                }
                                color={"blue"}
                                sx={{ textDecoration: "underline" }}
                              >
                                {rows.hcp_signature_filename}
                              </Typography>
                            </Button>
                          )}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ textAlign: "center" }}
                        >
                          {rows.hcp_name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {rows.national_id === "" || rows.national_id === null
                            ? "NA"
                            : rows.national_id}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {rows.professional_id === "" ||
                          rows.professional_id === null
                            ? "NA"
                            : rows.professional_id}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {rows.customer_id ? rows.customer_id : "NA"}
                        </TableCell>
                      </>
                    )}
                  </>
                </TableRow>
              ))}
            </TableBody>
          )}

          <TableCell colSpan={9} sx={{ p: 0, borderTopStyle: "inset" }}>
            <TablePagination
              sx={{
                color: "#1976d2",
                ".MuiTablePagination-selectLabel,.MuiTablePagination-select,.MuiTablePagination-displayedRows,.MuiTablePagination-actions ":
                  {
                    fontWeight: "bold",
                  },
              }}
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={props.rows.length}
              rowsPerPage={rpg}
              page={pg}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableCell>
        </Table>
      </TableContainer>
      {/* mobile device dashboard table */}
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: "center",
          display: { xs: "flex", sm: "flex", md: "none" },
          pt: 2,
        }}
      >
        {/* <Card sx={{ width: "80%" }}> */}
        <TableContainer component={Paper}>
          {props.rows.length === 0 ? (
            <Box sx={{ textAlign: "-webkit-center" }}>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td,&:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    No Results Found
                  </TableCell>
                </TableRow>
              </TableBody>
            </Box>
          ) : (
            <>
              <Table aria-label="simple table">
                {props.rows.slice(pg * rpg, pg * rpg + rpg).map((rows) => (
                  <>
                    {dataList.length > 6 ? (
                      <>
                        <TableRow>
                          <StyledtableHead>
                            <StyledTableCell>HCP First Name</StyledTableCell>
                          </StyledtableHead>
                          <StyledTableCellRow>
                            {rows.hcp_firstname}
                          </StyledTableCellRow>
                        </TableRow>
                        <TableRow>
                          <StyledtableHead>
                            <StyledTableCell>HCP Last Name</StyledTableCell>
                          </StyledtableHead>
                          <StyledTableCellRow>
                            {rows.hcp_lastname}
                          </StyledTableCellRow>
                        </TableRow>
                        <TableRow>
                          <StyledtableHead>
                            <StyledTableCell>Specialization</StyledTableCell>
                          </StyledtableHead>
                          <StyledTableCellRow>
                            {rows.hcp_specialization}
                          </StyledTableCellRow>
                        </TableRow>
                        <TableRow>
                          <StyledtableHead>
                            <StyledTableCell>Professional ID</StyledTableCell>
                          </StyledtableHead>
                          <StyledTableCellRow>
                            {rows.professional_id}
                          </StyledTableCellRow>
                        </TableRow>
                        {readMore && (
                          <>
                            <TableRow>
                              <StyledtableHead>
                                <StyledTableCell>National ID</StyledTableCell>
                              </StyledtableHead>
                              <StyledTableCellRow>
                                {rows.national_id}
                              </StyledTableCellRow>
                            </TableRow>
                            <TableRow>
                              <StyledtableHead>
                                <StyledTableCell>Email ID</StyledTableCell>
                              </StyledtableHead>
                              <StyledTableCellRow>
                                {rows.email_id}
                              </StyledTableCellRow>
                            </TableRow>
                            <TableRow>
                              <StyledtableHead>
                                <StyledTableCell>Meeting ID</StyledTableCell>
                              </StyledtableHead>
                              <StyledTableCellRow>
                                {rows.meeting_id}
                              </StyledTableCellRow>
                            </TableRow>
                            <TableRow>
                              <StyledtableHead>
                                <StyledTableCell>Meeting Date</StyledTableCell>
                              </StyledtableHead>
                              <StyledTableCellRow>
                                {new Date(rows.meeting_date).toLocaleDateString(
                                  "en-GB"
                                )}
                              </StyledTableCellRow>
                            </TableRow>

                            <TableRow>
                              <StyledtableHead>
                                <StyledTableCell>E-signature</StyledTableCell>
                              </StyledtableHead>
                              <StyledTableCellRow>
                                <Button style={{ textTransform: "none" }}>
                                  <Typography
                                    download={rows.hcp_signature_filename}
                                    onClick={() =>
                                      handleSignDownload(
                                        rows.attendee_id,
                                        rows.hcp_signature_filename
                                      )
                                    }
                                  >
                                    {rows.hcp_signature_filename}
                                  </Typography>
                                </Button>
                              </StyledTableCellRow>
                            </TableRow>
                          </>
                        )}
                        <CardActions>
                          <Button
                            size="small"
                            onClick={handleReadMore}
                            sx={{ fontWeight: "bold" }}
                          >
                            {readMore === true ? "Read Less" : "Read More"}
                          </Button>
                        </CardActions>
                      </>
                    ) : (
                      <>
                        <TableRow>
                          <StyledtableHead>
                            <StyledTableCell>HCP Name</StyledTableCell>
                          </StyledtableHead>
                          <StyledTableCellRow>
                            {rows.hcp_name}
                          </StyledTableCellRow>
                        </TableRow>
                        <TableRow>
                          <StyledtableHead>
                            <StyledTableCell>National ID</StyledTableCell>
                          </StyledtableHead>
                          <StyledTableCellRow>
                            {rows.national_id}
                          </StyledTableCellRow>
                        </TableRow>
                        {readMore && (
                          <>
                            <TableRow>
                              <StyledtableHead>
                                <StyledTableCell>
                                  Professional ID
                                </StyledTableCell>
                              </StyledtableHead>
                              <StyledTableCellRow>
                                {rows.professional_id}
                              </StyledTableCellRow>
                            </TableRow>
                            <TableRow>
                              <StyledtableHead>
                                <StyledTableCell>Customer ID</StyledTableCell>
                              </StyledtableHead>
                              <StyledTableCellRow>
                                {rows.customer_id ? rows.customer_id : "NA"}
                              </StyledTableCellRow>
                            </TableRow>
                          </>
                        )}
                        <CardActions>
                          <Button
                            size="small"
                            onClick={handleReadMore}
                            sx={{ fontWeight: "bold" }}
                          >
                            {readMore === true ? "Read Less" : "Read More"}
                          </Button>
                        </CardActions>
                      </>
                    )}
                  </>
                ))}
              </Table>
            </>
          )}
          <Box sx={{ textAlign: "-webkit-right", borderTop: "2px solid grey" }}>
            <TableRow>
              <TableCell sx={{ p: 0 }}>
                <TablePagination
                  sx={{
                    color: "#1976d2",
                    ".MuiTablePagination-selectLabel,.MuiTablePagination-select,.MuiTablePagination-displayedRows,.MuiTablePagination-actions ":
                      {
                        fontWeight: "bold",
                      },
                  }}
                  rowsPerPageOptions={[5, 10, 15]}
                  count={props.rows.length}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableCell>
            </TableRow>
          </Box>
        </TableContainer>
        {/* </Card> */}
      </Box>
      {/* </Paper> */}
    </>
  );
}
